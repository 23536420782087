import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CRestaurantPlan,
  CSectTitle03,
  CSectTitle04,
  CTable,
  CTable02,
  CAnchorNav02,
  CPlanMedia02,
  CGeneralCard,
  LOtherOiwai,
  LContact03,
} from '../../../components/_index';
import { restaurantPlanEventChoice } from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const eventclass = ['お祝い'];

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'お祝いプラン',
            sub: <>CELEBRATION</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/oiwai/oiwai_plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/oiwai_plan/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect04 u_bgGray">
        <LWrap>
          <CSectTitle04 title={'お祝いプラン'} tag={'h2'} exClass="u_mb0" />
          <p className="u_tac_pc u_fs16 u_mb30">
            お子様の初節句や成人式、ご家族のお誕生日、ご長寿のお祝いなど、
            <br />
            人生の節目を祝う大切な日を、個室でゆっくりとお過ごしいただけるプラン。
            <br />
            お食事とともにご家族の大切な思い出づくりにぜひご利用ください。
          </p>
          <CTable02
            exClass="u_bgWhite"
            data={[
              {
                title: '詳細',
                content: (
                  <>
                    <p>プランに含まれるもの</p>
                    <ul className="c_circleList u_mt10">
                      <li>特別コース料理:大人4名様分</li>
                      <li>個室料</li>
                    </ul>
                    <p className="u_mt15">
                      お祝い商品は下記の4種より1つお選びいただけます。
                    </p>
                    <ul className="c_circleList u_mt10">
                      <li>メッセージ入りケーキ</li>
                      <li>花束</li>
                      <li>スパークリングワイン</li>
                      <li>お子様料理</li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="l_sect u_pt70">
        <LWrap>
          <CAnchorNav02
            exClass="u_mb0"
            data={[
              {
                title: (
                  <>
                    鉄板焼 <br className="u_sp" />
                    すみだ
                  </>
                ),
                link: {
                  href: '/oiwai/oiwai_plan/#sumida',
                },
              },
              {
                title: (
                  <>
                    日本料理 <br className="u_sp" />
                    源氏香
                  </>
                ),
                link: {
                  href: '/oiwai/oiwai_plan/#genjikoh',
                },
              },
              {
                title: (
                  <>
                    中国料理 <br className="u_sp" />
                    桂花苑
                  </>
                ),
                link: {
                  href: '/oiwai/oiwai_plan/#kei_ka_en',
                },
              },
            ]}
          />
          <div id="sumida" className="u_pt100">
            <CSectTitle03 title={'鉄板焼 すみだ'} exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_store.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_store__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/sumida/',
                  },
                  title: '鉄板焼 すみだ',
                  text: (
                    <>
                      ホテル最上階の景色とともに、目の前で焼き上げられていく和牛や魚介類をご堪能いただけます。
                      <br />
                      慶事のひとときを華やかにおもてなしする、ライブ感溢れる鉄板焼をお楽しみください。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['鉄板焼 すみだ'],
                eventclass
              )}
              eventflag={true}
              buttonClass={'c_btn__bgWhite03'}
              exClass={'u_mb0'}
            />
          </div>
          <div id="genjikoh" className="u_pt100">
            <CSectTitle03 title={'日本料理 源氏香'} exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_store02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_store02__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/genjikoh/',
                  },
                  title: '日本料理 源氏香',
                  text: (
                    <>
                      落ち着きある雰囲気の中、日本庭園を眺めながら、ご家族と和やかに過ごせる風情ある空間。
                      <br />
                      お祝いの席に彩を添える、繊細な心遣いと四季折々の食材を生かした日本料理をご用意しております。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['日本料理 源氏香'],
                eventclass
              )}
              eventflag={true}
              buttonClass={'c_btn__bgWhite03'}
              exClass={'u_mb0'}
            />
          </div>
          <div id="kei_ka_en" className="u_pt100">
            <CSectTitle03 title={'中国料理 桂花苑'} exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_store03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_store03__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/kei_ka_en/',
                  },
                  title: '中国料理 桂花苑',
                  text: (
                    <>
                      ご家族で料理を囲み、喜びを分かち合うかけがえのないひととき。
                      <br />
                      ご多幸と健康への願いを込めて、お祝いの席にふさわしいバラエティあふれる広東料理の豊かな味わいをお楽しみください。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['中国料理 桂花苑'],
                eventclass
              )}
              eventflag={true}
              buttonClass={'c_btn__bgWhite03'}
              exClass={'u_mb0'}
            />
          </div>
          <div className="u_pt100">
            <CSectTitle03 title={'その他オプション'} exClass="u_mb40" />
            <CPlanMedia02
              exClass={'u_mb0'}
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/oiwai_plan/img_oiwai_plan.png',
                    alt: '',
                  },
                  title: <>スタジオ撮影(1ポーズ)</>,
                  definition: [
                    {
                      title: <>料金</>,
                      text: <>12,320円</>,
                    },
                  ],
                  note: (
                    <>
                      <ul className="c_noteList">
                        <li>通常 15,400円</li>
                        <li>写真室は水曜日定休(祝日を除く)となります。</li>
                        <li>料金には消費税が含まれます。</li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <LOtherOiwai exClass="u_pt0" />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'お祝い・記念日',
              path: '/oiwai/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title={'ご予約・お問合せ'}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
